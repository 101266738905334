.displayNone{
    display: none;
}

.loadingMask {
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: #444444;
    opacity: 0.7;
}

.panelLoad {
    z-index: 1002;
    margin-left: -20px !important;
    margin-top: -35px !important;
    -webkit-animation: panel 2.2s infinite forwards !important;
    animation: panel 2.2s infinite forwards !important;
}

.panel-spinner {
    margin: 0 auto !important;
    /* position: absolute !important; */
    left: 50% !important;
    top: 50% !important;
}

.loadingSpinner{
    position:absolute;
    left:50%;
    top:50%;
}

.circle {
  animation:spin 3s linear infinite both;
  background:#AFD000;
  border-radius:100vmax;
  /* margin:calc(50vh - 5vmin) calc(50vw - 1vmin); */ /* Half the longest viewport width minus half the width/height of the circle. */
  margin:60vh calc(50vw - 1vmin);
  height:5vmin;
  position:absolute;
  width:5vmin;
}
.circle + .circle {
  animation:spin 3s linear 1s infinite both;
  background:#AFD000;
}
.circle + .circle + .circle {
  animation:spin 3s linear 2s infinite both;
  background:#AFD000;
}
@keyframes spin {
  0% {
    transform: rotate(360deg) translate(0vmax,-10vmax);
	}
  50% {
    transform: rotate(180deg) translate(0vmax,0vmax);
  }
	100% {
    transform: rotate(0deg) translate(0vmax,-10vmax);
	}
}