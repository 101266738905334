.calendar {
  .heading-main-calendar {
    display: flex;
    margin-top: 28px;
    margin-right: 40px;
    margin-left: 35px;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 400;
      font-size: 48px;
      line-height: 58px;
      letter-spacing: 0.0327273px;

      color: #979797;
    }
    button {
      width: 97px;
      height: 32px;
      font-weight: 300;
      font-size: 12px;

      line-height: 15px;

      border: none;

      color: #000000;

      background: #c4c4c4;
      border-radius: 7px;
    }
  }
  .border-bottom {
    margin-top: 10px;
    margin-right: 40px;
    margin-left: 35px;
    left: 157px;
    top: 91px;

    border: 1px solid #000000;
  }
  .calendar-cover {
    margin: 50px;
    padding: 10px 0;
    border: 1px solid rgb(240, 240, 240);
    position: relative;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  /* Heading */

  color: #3b3b3b;
  .header-inner {
    display: flex;
    align-items: center;
    gap: 9.5px;
    img {
      cursor: pointer;
    }
  }
}
.event-top {
  margin-top: 20px;

  display: flex;
  align-items: center;
  // justify-content: space-between;
  background-color: #d8d7d7;
  padding: 8px 0;
  padding-right: 19px;
  height: 33px;
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    /* Heading */

    color: #3b3b3b;
  }
  // opacity: 0.25;
  border-radius: 4px;
  &::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 33px;

    background: #625f5f;
    border-radius: 4px 0px 0px 4px;
  }
}
.event-top-inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 15px;
}

.content {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 12px;
  color: #979797;
}

.header-edit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-weight: 500;
    font-size: 21.251px;
    line-height: 26px;
    padding-left: 35px;

    color: #979797;
  }
  img {
    cursor: pointer;
  }
}
.content {
  // padding-left: 35px;
  .mass-announcement {
    display: flex;
    align-items: center;
    gap: 7.1px;
    margin-left: 30px;
  }

  .content-group {
    display: flex;
    align-items: flex-start;
    gap: 15.33px;
    margin-bottom: 24.29px;
    select {
      width: 311.18px;
      height: 36.43px;

      /* Grey 100 */

      background: #f5f5f5;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);
      border-radius: 8px;
      border: none;
      padding: 10px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url('data:image/svg+xml;utf8,<svg width="10" height="10" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.99992 7.58586L16.2928 0.292969L17.707 1.70718L8.99992 10.4143L0.292818 1.70718L1.70703 0.292969L8.99992 7.58586Z" fill="black"/></svg>');
      background-repeat: no-repeat;
      background-position-y: 50%;
      background-position-x: 96%;
      padding: 5px;
    }
    textarea {
      width: 311.18px;
      height: 106.25px;
      padding: 8px;

      /* Grey 100 */

      background: #f5f5f5;
      border-radius: 8px;
    }
    .date-pick {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 22.77px !important;
      .date-pick-1 {
        width: 150.2px;
        height: 36.43px;
        position: relative;
        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 15px;
          width: 10px;
          height: 6.11px;
        }

        /* Grey 100 */

        input {
          width: 144.2px;
          height: 36.43px;
          border: none;
          background: #f5f5f5;
          border-radius: 8px;
          padding: 8px;
          font-weight: 400;
          font-size: 12px;
          line-height: 21px;

          /* Heading */

          color: #3b3b3b;
        }
      }
    }
  }
}

.example-custom-input {
  // background-color: red;
}
.create-outer {
  display: flex;
  justify-content: right;
  .create-btn {
    width: 131px;
    height: 33.48px;
    left: 1231px;
    top: 714px;

    background: #979797;
    border-radius: 4.36665px;
    font-weight: 400;
    font-size: 13.1px;
    line-height: 16px;

    text-align: center;
    letter-spacing: -0.727776px;

    color: #ffffff;
    border: none;
  }
}

#calendar-modal-title {
  border: none;
  outline: none;
  width: 80%;
  border-width: 0px;
  font-size: 1.5em;
  &::placeholder {
    font-style: normal;
    font-size: 1em;
  }
}
