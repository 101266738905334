.addContentHomeMainDiv {
  min-height: 100vh;
  .addComponentMainDiv {
    margin-top: 20px;
    .topBar {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-weight: 500;
        font-size: 20px;
        line-height: 95%;
        color: #979797;
      }
      .btnDiv {
        font-size: 12px;
        margin-right: 100px;
      }
    }
    .contentDiv {
      margin-top: 20px;
      .inputField {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        label {
          font-size: 24px;
          font-weight: 400;
          color: #979797;
          margin-bottom: 10px;
        }
        input {
          width: 898px;
          height: 51px;
          background: #f9f9f9;
          border: 1px solid #bcbaba;
          border-radius: 8px;
        }
        textarea {
          width: 898px;
          height: 255px !important;
          background: #f9f9f9;
          border: 1px solid #bcbaba;
          border-radius: 8px;
        }
      }
    }
    .footerBar {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 20px;
      button {
        width: 180px;
        height: 46px;
        border-radius: 6px;
        outline: none;
        border: 0ch;
        color: white;
        margin-right: 20px;
      }
      .create {
        background-color: #979797;
      }
      .cancel {
        background-color: #cdcdcd;
      }
    }
  }
}
//upload character
.characterUploadScreen{
  .inputField {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    label {
      font-size: 19px;
      font-weight: 400;
      color: #000;
      margin-bottom: 10px;
    }
    input {
      display: block;
      height: 51px;
      background: #f9f9f9;
      border: 1px solid #bcbaba;
      border-radius: 8px;
      padding:0 10px;
    }
    textarea {
      display: block;
      height: 255px !important;
      background: #f9f9f9;
      border: 1px solid #bcbaba;
      border-radius: 8px;
      padding: 10px;
    }
  }
  .image-upload .image-upload-inner{
    width:100%;
    margin-top:15px;
    margin-bottom: 15px;
    height: 180px;
  }
  .image-upload .image-upload-inner label img{
    width: 45px;
  }
  .footerBar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 20px;
    button {
      width: 180px;
      height: 46px;
      border-radius: 6px;
      outline: none;
      border: 0ch;
      color: white;
      margin-right: 20px;
    }
    .create {
      background-color: #EF5B2C;
    }
    .cancel {
      background-color: #cdcdcd;
    }
  }
}
// EDIT CONTENT CSS
.editContentMainDiv {
  .topBar {
    margin-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 95%;
      color: #979797;
    }
    .btnDiv {
      font-size: 12px;
      margin-right: 100px;
    }
  }

  .fileDiv {
    padding-right: 30px;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .courseVideoDivMain {
      width: 340px;
      height: 251px;
      background: #ffffff;
      border: 1px solid #979797;
      border-radius: 10px;
      padding: 30px;
      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-weight: 500;
          font-size: 20px;
          color: #979797;
        }
        .acionBtn {
          font-weight: 500;
          font-size: 16px;
          text-decoration-line: underline;
          color: $primaryColor;
          cursor: pointer;
        }
      }
      .videoContainer {
        display: flex;
        margin-top: 20px;
        .videoDiv {
          width: 141px;
          height: 138px;
          border: 0.608133px solid #c2c2c2;
          border-radius: 6.08133px;
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 55px;
            margin-bottom: 10px;
          }
          span {
            font-weight: 600;
            font-size: 10.9464px;
            color: #979797;
            text-overflow: ellipsis;
            display: block;
            width:100%;
            overflow: hidden;
            direction: rtl;
            white-space: nowrap;
            padding:2px;
          }
        }
      }
      .pdfContainer {
        margin-top: 20px;
        .pdfDiv {
          width: 276px;
          height: 152px;
          border: 1px dashed #979797;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 55px;
            margin-bottom: 10px;
          }
          .fileName {
            width: 99px;
            height: 24px;
            // background: #c4c4c4;
            background: rgba(239, 91, 44, 0.2);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 10.9464px;
            color: #979797;
          }
        }
      }
    }
  }

  .quizDivMain {
    margin-top: 30px;
    border: 1px solid #979797;
    border-radius: 10px;
    padding: 30px;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: 500;
        font-size: 20px;
        color: #979797;
      }
      .acionBtn {
        font-weight: 500;
        font-size: 16px;
        text-decoration-line: underline;
        color: #EF5B2C;
        cursor: pointer;
      }
    }
    .questionContentDiv {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-right: 50px;
      .questionDiv {
        margin-top: 20px;
        @media(min-width:1200px){
          width:48%;
        }
        .topdiv {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-weight: 400;
            font-size: 18px;
            color: #979797;
          }
        }

        .innerDiv {
          margin-top: 10px;
          min-width: 50%;
          border: 1px solid #c2c2c2;
          border-radius: 8px;
          padding: 30px;
          .question {
            font-weight: 400;
            font-size: 16px;
            color: #979797;
          }
          .options {
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            div {
              // background: #dadada;
              background: rgba(239, 91, 44, 0.2);
              border-radius: 8px;
              margin-right: 10px;
              margin-top: 10px;
              color: #EF5B2C;
              padding: 8px;
            }
          }
        }
      }
    }
  }

  // Conversation css of game
  .conversationDivMain {
    margin-top: 30px;
    height: 385.49px;
    background: #f9f9f9;
    border: 1.05019px dashed #bcbaba;
    border-radius: 13.6524px;
    display: flex;
    .leftDiv {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: 1.05019px solid #bcbbbb;

      .titleText {
        width: 430px;
        margin-bottom: 10px;
        color: #bfbfbf;
      }
      .fileMainContainer {
        .fileDivMain {
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          border-radius: 39.9071px;
          width: 430px;
          height: 40px;
          background-color: #979797;
          color: #ffffff;
          position: relative;
          overflow: hidden;
          span {
            padding: 10px;
            position: absolute;
            left: 0;
            margin-left: 5px;
            font-weight: 400;
            font-size: 14.7026px;
            line-height: 18px;
          }
          .fileNameDiv {
            width: 70%;
            background-color: #ffffff;
            border-top-left-radius: 39.9071px;
            border-bottom-left-radius: 39.9071px;
            padding: 5px;
            height: 100%;
            border-top: 1px #979797 solid;
            border-bottom: 1px #979797 solid;
            border-left: 1px #979797 solid;
            color: #979797;
            font-weight: 400;
            font-size: 14.7026px;
            line-height: 18px;
            display: flex;
            align-items: center;
            padding-left: 10px;
          }
          .fileNameDivFull {
            width: 90%;
            background-color: #ffffff;
            border-top-left-radius: 39.9071px;
            border-bottom-left-radius: 39.9071px;
            padding: 5px;
            height: 100%;
            border-top: 1px #979797 solid;
            border-bottom: 1px #979797 solid;
            border-left: 1px #979797 solid;
            color: #979797;
            font-weight: 400;
            font-size: 14.7026px;
            line-height: 18px;
            display: flex;
            align-items: center;
            padding-left: 10px;
          }
          .removeDiv {
            border-top: 1px #979797 solid;
            border-bottom: 1px #979797 solid;
            border-right: 1px #979797 solid;
            border-top-right-radius: 39.9071px;
            border-bottom-right-radius: 39.9071px;

            width: 10%;
            height: 100%;
            background-color: #ffffff;
            padding: 5px;
            img {
              cursor: pointer;
            }
          }
          .removeDiv {
            width: 10%;
          }
        }
      }
      .uploadBtn {
        width: 112.37px;
        height: 31.51px;
        background: #dadada;
        border-radius: 6.30112px;
        border: none;
        outline: 0ch;
        font-weight: 400;
        font-size: 13.6524px;
        line-height: 17px;
        text-align: center;
        color: #9a9a9a;
        margin-top: 10px;
      }
      .dividerLine {
        margin-top: 20px;
        margin-bottom: 20px;
        min-height: 2px;
        width: 350px;
        border-bottom: 2px #979797 solid;
      }
    }
    .rightDiv {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .previewText {
        width: 430px;
        margin-bottom: 10px;
        color: #bfbfbf;
      }
      .imageDiv {
        width: 430px;
        height: 270px;
        position: relative;

        .bgImage {
          width: 100%;
          height: 100%;
          object-fit: cover !important;
        }
        .avatarImg {
          position: absolute;
          left: 10%;
        }
        .dialogeText {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 94.28px;
          background: rgba(255, 255, 255, 0.8);
          border: 1.05019px solid rgba(0, 0, 0, 0.6);
          border-radius: 10.5019px;
          color: #9a9a9a;
          font-weight: 600;
          font-size: 13.6524px;
          line-height: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          .charatcterName {
            position: absolute;
            top: 0;
            left: 0;
            width: 99.77px;
            height: 22px;
            margin-top: -10px;
            margin-left: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 12.6022px;
            line-height: 15px;
            color: #9a9a9a;
            background-image: url("../../assets/Polygon.png");
          }
          span {
            width: 328.71px;
          }
        }
      }
    }
  }
  .footerBar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 20px;
    button {
      width: 180px;
      height: 46px;
      border-radius: 6px;
      outline: none;
      border: 0ch;
      color: white;
      margin-right: 20px;
    }
    .create {
      background-color: #979797;
    }
    .cancel {
      background-color: #cdcdcd;
    }
  }
}
.editContentTabWrapper .MuiTab-root{
  pointer-events: none;
}
// END
