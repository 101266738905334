@import "_variables.scss";
@import "./pages/Dashboard.scss";
@import "./pages/newSchoolEntry.scss";
@import "./components/Sidebar.scss";
@import "./pages/manageAccounts.scss";
@import "./pages/student.scss";
@import "./pages/Teacher.scss";
@import "./pages/Login.scss";
@import "./pages/Calendar.scss";
@import "./pages/Analytics.scss";
@import "./pages/WeeklyQuiz.scss";

@import "./pages/Leaderboard.scss";
@import "./pages/platformManagement.scss";
@import "./pages/addContentHome.scss";
@import "./pages/schoolHomeMain.scss";

@import "./components/backgroundImageUpload.scss";
@import "./components/globalGameManagement.scss";

@font-face {
  font-family: "WorkSans";
  src: url("../../public/fonts/WorkSans-Black.ttf") format("truetype");
  // url('/fonts/GlacialIndifference-Bold.woff') format('woff');
}
@font-face {
  font-family: "WorkSans";
  src: url("../../public/fonts/WorkSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "WorkSans";
  src: url("../../public/fonts/WorkSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "WorkSans";
  src: url("../../public/fonts/WorkSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "WorkSans";
  src: url("../../public/fonts/WorkSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "WorkSans";
  src: url("../../public/fonts/WorkSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "WorkSans";
  src: url("../../public/fonts/WorkSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "WorkSans";
  src: url("../../public/fonts/WorkSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "WorkSans";
  src: url("../../public/fonts/WorkSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
.home {
  background-color: red;
}

// Nav Links and Tabs

.nav-link {
  color: black;
  border: none;
  transition: none;
}

.nav-link.active {
  border: none;
}
.nav-link:hover {
  color: black;
  opacity: 0.7;
  border: none;
}

.nav-tabs {
  border: none;
}

.tabs-section-upload {
  .MuiTabs-flexContainer {
    // border: 1px solid red ;
    justify-content: space-between;
  }
}
//Buttons

.btn-secondary {
  margin: 0px 0px 23px 5px;
  background-color: #b5b5b5;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "WorkSans", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  font-size: 16px;
  color: black;
}
*:focus-visible {
  outline: none !important;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
.main {
  margin-left: 120px;
}
// accordion

.MuiAccordion-root {
  background: #dadada;

  margin-bottom: 18.31px;
  box-shadow: 0 0 0 rgb(0 0 0 / 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);

  border-radius: 10px;
}
.MuiAccordionDetails-root {
  background-color: white;

  border-bottom-left-radius: 10px;
  // padding: 31px 36px 58px 47px;
  border-bottom-right-radius: 10px;
}
.Mui-expanded {
  // background-color: white;
}

.MuiBox-root {
  border: 0;

  .MuiTabs-indicator {
    border-bottom: 4px solid #625f5f;
  }
  .Mui-selected {
    color: black;
  }
}
input[type="text"]::placeholder {
  font-style: italic;
  font-weight: 300;
  font-size: 13px;
  // padding: 5px;
  text-transform: capitalize;

  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.0327273px;

  // border: 1px solid red;
}

.heading-main {
  font-size: 48px;
  line-height: 58px;
  padding: 57px 0 0 35px;
}
.heading_main {
  padding-bottom: 10px;
  border-bottom: 1px solid #000000;
  font-size: 24px;
  font-weight: 400;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.0327273px;
}
.MuiTableCell-root {
  padding: 0 5px;
  height: 50px;
}
.school-btn-sm-outer {
  display: flex;
  margin-top: 23px;
  gap: 21px;
}
.school-btn {
  width: 210px;
  height: 34px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  border: none;

  background: $primaryColor;
  border-radius: 7px;
  color: white;
  text-transform: capitalize;
}
.school-btn-sm {
  width: 94px;
  background-color: $primaryColor;
}
.remove-btn {
  width: 67.81px;
  height: 24px;

  border: 1px solid #FF0000;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FF0000;
  border-radius: 8px;
  background-color: white;
}

.back-btn {
  width: 180.81px;
  padding-right: 65px;
  height: 46px;
  border: 1px solid #a7a7a7;
  font-weight: 400;
  font-size: 18px;
  color: white;
  border-radius: 8px;
  background-color: #625f5f;
}

.inspect-btn {
  width: 67.81px;
  height: 24px;

  border: 1px solid black;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: black;
  border-radius: 8px;
  background-color: white;
}

.large-btn {
  width: 150.81px;
  height: 24px;
  border: 1px solid #a7a7a7;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #a7a7a7;
  border-radius: 8px;
  background-color: white;
}

.container-page {
  padding: 38px;
  // padding-top: 0;
}
.container-new-page {
  padding-top: 0;
}
.modal-container {
  min-width: 480px;
  // min-height: 337px;
  min-height: 300px;
  background: #ffffff;
  border-radius: 7px;
  // overflow: scroll;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-container-analytics {
  max-width: 1129px;
  min-width: 800px;
  // min-height: 337px;
  max-height: 773px;
  min-height: 500px;
  background: #ffffff;
  padding: 47px 45px 30px 37px;
  border-radius: 7px;
  // overflow: scroll;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-container-inner-analytics {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-container-curve {
  max-width: 1500px;
  min-width: 1000px;

  background: #ffffff;
  padding: 47px 45px 30px 37px;
  border-radius: 7px;
  // overflow: scroll;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-container-inner-curve {
  display: flex;
  justify-content: space-between;
}

.curve-values {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.curve-value-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.input-box-outer-curve {
  position: relative;
  width: 210px;
  height: 34px;
  .input-box {
    width: 210px;
    height: 34px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);

    border-radius: 2px;

    padding: 9px;

    &::placeholder {
      font-family: WorkSans;
      font-style: italic;
      font-weight: 300;
      font-size: 14px;
    }
  }
}

.modal-container-warn {
  width: 520px;
  // max-width: 800px;
  overflow: auto;
  height: 247px;
  background: #ffffff;
  border-radius: 7px;
  // overflow: scroll;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-header,
.modal-header-upload {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: black;
  text-align: center;
  margin-bottom: 16px;
  padding: 5px;
  display: block;
}
.modal-header-upload {
  text-align: left;
}
.modal-container-inner {
  position: relative;
  padding: 28px 82px 34px 73px;

  min-width: 480px;
  max-width: 800px;
  overflow: auto;
  // min-height: 337px;
  min-height: 300px;
  max-height: 500px;
}
.modal-container-inner-upload {
  position: relative;
  // padding: 28px 82px 34px 73px;

  // width: 670px;
  padding: 10px;
  // height: 838px;

  width: 630px; //500px previously
  // max-width: 838px;
  overflow: auto;
  height: 600px;
  // max-height: 500px;
}
.modal-container-inner-warn {
  position: relative;
  padding: 35px 44px 0px 42px;

  width: 520px;
  // max-width: 800px;
  overflow: auto;
  height: 247px;
}
.open-btn,
.close-btn {
  width: 115px;
  height: 37px;

  background: $open-btn-color;
  border-radius: 7px;
  height: 37px;
  line-height: 37px;
  font-weight: 400;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.open-btn{
background-color: $primaryColor;
color: white;
}
.close-btn {
  background-color: $close-btn-color;
}
.confirm-close-btns {
  // position: absolute;
  display: flex;
  margin-top: 12px;

  align-items: center;
  justify-content: center;

  // bottom: ;
  gap: 20px;

  // left: 50%;

  // transform: translateX(-50%);
}

.modal-header {
  border: none !important;
}

.warning {
  display: flex;
  flex-direction: row;

  gap: 16px;
  margin-bottom: 30px;
  .warning-left {
    width: 10%;
  }
  .warning-right {
    width: 90%;
    font-weight: 400;
    font-size: 16px;
    line-height: 95%;
    /* or 15px */

    color: #979797;
  }
}

.breadcrumb-outer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 20px 0px 10px 0;
  border-bottom: 1px solid black;
  font-size: 24px;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.0327273px;
  .breadcrumb-title {
    text-transform: capitalize;
    cursor: pointer;
  }
}

.fc .fc-button-primary {
  background-color: #f5f5f5 !important;
  color: #979797 !important;
  border: none !important;
  text-transform: capitalize !important;
}
.fc-button-active {
  background-color: red !important;
  border: 1px solid red !important;
}
.fc-h-event .fc-event-title-container {
  height: 34.09px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  gap: 15px;
  background-color: rgb(229, 229, 229);
  // border-color: white;
  outline: 1px solid white;
  color: #979797;
  position: relative !important;
}
.fc-h-event .fc-event-title-container::before {
  content: "";
  width: 4.13px;
  height: 100%;
  // border: 5px solid red !important;
  // height: 100px ;
  // text-transform: capitalize !important;
  display: inline-block;

  background: #625f5f;
  border-radius: 4.13187px 0px 0px 4.13187px;
}

.fc .fc-button-group button{
  background: rgba(239, 91, 44, 0.2);
}
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background: $primaryColor !important;
  box-shadow: inset 0px -4.13187px 0px rgba(0, 0, 0, 0.15) !important ;
  border-radius: 8.26374px !important;
  font-weight: 500 !important;

  /* identical to box height */

  text-align: center;

  color: #ffffff !important;
}

.lessondiv .MuiPaper-root {
  border: 1.20417px solid rgba(0, 0, 0, 0.2);
}

.lessondiv .MuiCardContent-root {
  padding: 0px !important;
}

// for table header
.MuiTableCell-head {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
}

.MuiTableCell-body {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

// Apex Charts Axes

.apexcharts-yaxis {
  display: none;
}

.apexcharts-xaxis {
  display: none;
}



// content management 
.content-select{
  width: 440px;
height: 48px;


background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.2);
box-sizing: border-box;
border-radius: 5px;
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
background-image: url('data:image/svg+xml;utf8,<svg width="13" height="8" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99992 7.58586L16.2928 0.292969L17.707 1.70718L8.99992 10.4143L0.292818 1.70718L1.70703 0.292969L8.99992 7.58586Z" fill="black"/></svg>');
background-repeat: no-repeat;
background-position-y: 50%;
background-position-x: 96%;
padding: 11px 16px;
@media only screen and (max-width: 1200px) {
  width: 350px;
}
}
// warning messages
.alert-msg{
  // display: block;

  font-size: 16px;
  text-align: center;

}
.danger-msg{
  color: red;
}
.success-msg{
  color: green;
}



.disabled-btn{
  background: #b5b5b5 !important;
  // color: black;
}

// date picker navigation icons


// pagination mui
.MuiPagination-root	{
  // border:1px solid red;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiPagination-ul{
  li{
    .Mui-selected{
      // border: 1px solid red;
      background-color: $primaryColor;
      color:white;
      &:hover{
        background-color: $primaryColor;
        color:white;

      }

    }
  }
}
// .MuiPagination-text	{
//   color:white;
// }

.title-content
  {  background: #f9f9f9;
border: 1px solid #bcbaba;
border-radius: 8px;
padding: 5px;
width: 70%;
margin: auto;
}
.video-title{
  margin-left: 10px;
  width: 90%;
}

.title-content-languagemgmt{
  margin-top: 15px;
}
.image-upload-inner-languagemgmt{
  height: 200px !important;
}

.video-analyzer{
  padding: 20px;
  width: 100%;
}
// css for loading spinner


.bulbaSpinnerDialogBoxPanel {
	padding: 0px 10px 10px 10px;
	border-radius: 8px;
}

.bulbaSpinner {
	position: absolute;
	top: calc(50% - 150px);
	left: calc(50% - 150px);
	width: 300px;
	height: 300px;
	border-radius: 50%;
	perspective: 800px;
}

.bulbaSpinnerInner {
	position: absolute;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.bulbaSpinnerInner.one {
	left: 0%;
	top: 0%;
	animation: rotate-one 1s linear infinite;
	border-bottom: 5px solid white;
}

.bulbaSpinnerInner.two {
	right: 0%;
	top: 0%;
	animation: rotate-two 1s linear infinite;
	border-right: 5px solid crimson;
}

.bulbaSpinnerInner.three {
	right: 0%;
	bottom: 0%;
	animation: rotate-three 1s linear infinite;
	border-top: 5px solid cadetblue;
}

#bulbaSpinner2Group {
	position: relative;
	width: 100px;
	height: 15px;
	margin: auto;
}

.bulbaSpinner2Group {
	position: absolute;
	top: 0;
	background-color: rgb(0, 191, 255);
	width: 10px;
	height: 10px;
	animation-name: bounce_bulbaSpinner2Group;
	-o-animation-name: bounce_bulbaSpinner2Group;
	-ms-animation-name: bounce_bulbaSpinner2Group;
	-webkit-animation-name: bounce_bulbaSpinner2Group;
	-moz-animation-name: bounce_bulbaSpinner2Group;
	animation-duration: 1.69s;
	-o-animation-duration: 1.69s;
	-ms-animation-duration: 1.69s;
	-webkit-animation-duration: 1.69s;
	-moz-animation-duration: 1.69s;
	animation-iteration-count: infinite;
	-o-animation-iteration-count: infinite;
	-ms-animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	animation-direction: normal;
	-o-animation-direction: normal;
	-ms-animation-direction: normal;
	-webkit-animation-direction: normal;
	-moz-animation-direction: normal;
	transform: scale(.3);
	-o-transform: scale(.3);
	-ms-transform: scale(.3);
	-webkit-transform: scale(.3);
	-moz-transform: scale(.3);
	border-radius: 9px;
	-o-border-radius: 9px;
	-ms-border-radius: 9px;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
}

#bulbaSpinner2Group_1 {
	left: 0;
	animation-delay: 0.68s;
	-o-animation-delay: 0.68s;
	-ms-animation-delay: 0.68s;
	-webkit-animation-delay: 0.68s;
	-moz-animation-delay: 0.68s;
}

#bulbaSpinner2Group_2 {
	left: 13px;
	animation-delay: 0.85s;
	-o-animation-delay: 0.85s;
	-ms-animation-delay: 0.85s;
	-webkit-animation-delay: 0.85s;
	-moz-animation-delay: 0.85s;
}

#bulbaSpinner2Group_3 {
	left: 27px;
	animation-delay: 1.01s;
	-o-animation-delay: 1.01s;
	-ms-animation-delay: 1.01s;
	-webkit-animation-delay: 1.01s;
	-moz-animation-delay: 1.01s;
}

#bulbaSpinner2Group_4 {
	left: 40px;
	animation-delay: 1.18s;
	-o-animation-delay: 1.18s;
	-ms-animation-delay: 1.18s;
	-webkit-animation-delay: 1.18s;
	-moz-animation-delay: 1.18s;
}

#bulbaSpinner2Group_5 {
	left: 54px;
	animation-delay: 1.35s;
	-o-animation-delay: 1.35s;
	-ms-animation-delay: 1.35s;
	-webkit-animation-delay: 1.35s;
	-moz-animation-delay: 1.35s;
}

#bulbaSpinner2Group_6 {
	left: 67px;
	animation-delay: 1.52s;
	-o-animation-delay: 1.52s;
	-ms-animation-delay: 1.52s;
	-webkit-animation-delay: 1.52s;
	-moz-animation-delay: 1.52s;
}

#bulbaSpinner2Group_7 {
	left: 81px;
	animation-delay: 1.69s;
	-o-animation-delay: 1.69s;
	-ms-animation-delay: 1.69s;
	-webkit-animation-delay: 1.69s;
	-moz-animation-delay: 1.69s;
}

#bulbaSpinner2Group_8 {
	left: 94px;
	animation-delay: 1.86s;
	-o-animation-delay: 1.86s;
	-ms-animation-delay: 1.86s;
	-webkit-animation-delay: 1.86s;
	-moz-animation-delay: 1.86s;
}

@keyframes bounce_bulbaSpinner2Group {
	0%		{transform: scale(1); background-color: rgb(0, 191, 255);}
	100%	{transform:scale(.3); background-color :rgb(255,255,255);}
}

@-o-keyframes bounce_bulbaSpinner2Group {
	0%		{-o-transform: scale(1); background-color: rgb(0, 191, 255);}
	100%	{-o-transform:scale(.3); background-color: rgb(255,255,255);}
}

@-ms-keyframes bounce_bulbaSpinner2Group {
	0%		{-ms-transform: scale(1); background-color: rgb(0, 191, 255);}
	100%	{-ms-transform: scale(.3); background-color:rgb(255,255,255);}
}

@-webkit-keyframes bounce_bulbaSpinner2Group {
	0%		{-webkit-transform: scale(1); background-color: rgb(0, 191, 255);}
	100%	{-webkit-transform: scale(.3); background-color: rgb(255,255,255);}
}

@-moz-keyframes bounce_bulbaSpinner2Group {
	0%		{-moz-transform: scale(1); background-color: rgb(0, 191, 255);}
	100%	{-moz-transform: scale(.3); background-color: rgb(255,255,255);}
}

@keyframes rotate-one {
	0% 		{ transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);}
	100% 	{ transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);}
}

@keyframes rotate-two {
	0% 		{transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);}
	100%	{transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);}
}

@keyframes rotate-three {
	0% 		{transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);}
	100%	{transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);}
}







