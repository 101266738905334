.student{
    .heading{
        font-weight: 700;
font-size: 24px;
line-height: 29px;
margin-bottom: 33px;

color: #000000;
    }
    .top-section{
        display: flex;
       align-items: center;
        gap: 19px;
        margin-top: 29.5px;

        div{
            display: flex;
            flex-direction: column;
            gap: 8px;
            .uploadIcon{
                width: 20px;
                height: 20px;
            }
            
          
        }
        div:nth-child(2){

            span:nth-child(1){
                font-family: 'WorkSans';
                font-style: normal;
                font-weight: 700;
                font-size: 36px;
                line-height: 44px;
                
                color: #000000;
            }
            span:nth-child(2){
                font-family: 'WorkSans';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;

color: #000000;
                
            }
        }
        
        div:nth-child(3){
            margin-top: 15px;
           display: flex;
        //    align-items: center;
gap: 25px;            span{
                font-style: italic;
font-weight: 400;
font-size: 12px;
line-height: 15px;
            }
        }
        
    }
    .bottom-section{
        margin-top: 38px;
        // border: 1px  solid red;
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: flex-start;
        .bottom-section-left{
            padding:27px 30px 50px 45px;
            width: 50%;

            max-width: 55%;
            border: 1px solid rgba(0, 0, 0, 0.2);
        // border: 1px  solid rgb(6, 174, 0);

box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 7px;
.stats{
    display: flex;
    gap: 36px;
    flex-flow: row wrap;
    margin-bottom: 59px;
    .stats-group{
        width: 200px;
        // border: 1px solid red;
height: 80px;
display: flex;
gap: 22px;
align-items: center;
@media only screen and (min-width: 1368px) {
    width: 220px;
        
      }
.info-stats{
    display: flex;
   flex-direction: column;
//    justify-content: center;
   gap: 10px;
//   border: 1px solid red;
    .header{
        font-weight: 500;
font-size: 12px;
line-height: 15px;
color: black;
    }
    .score{
        font-weight: 500;
font-size: 30px;
line-height: 44px;
word-break: break-word;
@media only screen and (min-width: 1368px) {
font-size: 36px;

    
  }
  span{
    font-weight: 500;
font-size: 12px;
line-height: 15px;
}
    }
}
    }
}

        }
        .bottom-section-right{
            width: 60%;
            // padding:27px 30px 50px 45px;
            min-width: 50%;
//             border: 1px solid rgba(0, 0, 0, 0.2);
// box-sizing: border-box;
// box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
// border-radius: 7px;
.bottom-section-right-top{
   
// height: 189px;
padding:27px 46px 38px;

border: 1px solid rgba(0, 0, 0, 0.2);
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 7px;
.info{
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    span{
        font-weight: 700;
font-size: 14px;
line-height: 17px;
    }
    span:nth-child(1){
        // border: 1px solid red;
        width: 25%;
    }
    span:nth-child(2){
        // border: 1px solid red;
        width: 60%;
    } span:nth-child(3){
        // border: 1px solid red;
        width: 15%;
    }

}
.info-values{
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    span{
        font-weight: 400;
        font-size: 14px;
        word-wrap: break-word;
        line-height: 17px;
    }
    span:nth-child(1){
        // border: 1px solid red;
        width: 25%;
    }
    span:nth-child(2){
        // border: 1px solid red;
        width: 60%;
    } span:nth-child(3){
        // border: 1px solid red;
        width: 15%;
    }

}


}
.bottom-section-right-bottom{
   margin-top: 16px;
padding:27px 46px 38px;

border: 1px solid rgba(0, 0, 0, 0.2);
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 7px;

.header{
    display: flex;
    div:nth-Child(1){
        // border: 1px solid red;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 30%;
        span:nth-Child(1){
            font-weight: 600;
font-size: 18px;
line-height: 22px;
        }
        span:nth-Child(2){
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
        }
    }
  
    div:nth-Child(2){
        font-weight: 600;
font-size: 18px;
line-height: 22px;
        // border: 1px solid red;
       
        width: 20%;
    }
    div:nth-Child(3){
        // border: 1px solid red;
       
        width: 50%;
    }
}
.border-bottom{
    border: 1px solid rgba(0, 0, 0, 0.5);

    margin-top: 20px;
    margin-bottom: 18px;
}
.data-bottom{
    .header{
        margin-bottom: 35px;
    .header-first{
        span:nth-Child(1){
            font-weight: 600;
font-size: 14px;
line-height: 17px;

        }
    }
div:nth-Child(2){
    font-weight: 400;
font-size: 14px;
line-height: 17px;
}}
}
}

        }
    }
}