.login-container {
  // width: 100vw;

  min-height: 100vh;
  overflow-x: hidden;

  background-color: white;
  display: flex;
  flex-direction: row;

  gap: 10px;

  .login-left {
    position: relative;

    width: 50%;
    background: #007fff;
    background-image: url("../../assets/loginPageImage.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    // border: 1px solid red;
    flex-direction: column;
    z-index: 1;
    // .overlay {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: black;
    //   opacity: 0.4;
    // }

    .logo {
      margin-left: 12px;
      position: absolute;
      top: 0px;
    }
    .msg {
      // border: 1px solid red;
      margin-bottom: 83px;
      margin-left: 50px;
      margin-right: 50px;
      z-index: 10;
    }
    .welcome-msg {
      // position: absolute;
      // width: 440px;
      // height: 180px;
      // bottom: 127px;
      // left: 50px;
      word-wrap: break-word;
      overflow-wrap: break-word;

      font-family: "WorkSans";
      font-style: normal;
      font-weight: 700;
      font-size: 72px;
      line-height: 90px;
      color: white;
    }
    span {
      //     width: 620px;
      // height: 36px;
      // left: 50px;
      // bottom: 83px;
      // position: absolute;
      word-wrap: break-word;
      overflow-wrap: break-word;
      font-family: "WorkSans";

      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;

      color: #ffffff;
    }
  }
  .login-right {
    width: 50%;
    .heading {
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      /* identical to box height */

      text-align: center;
      margin-bottom: 50px;
      margin-top: 150px;

      @media only screen and (min-width: 1400px) {
        margin-bottom: 140px;
        margin-top: 240px;
      }
    }
    .form {
      display: flex;
      align-items: center;
      margin: auto;

      flex-direction: column;
      gap: 27px;
      .form_control {
        display: flex;
        flex-direction: column;

        gap: 10px;
        .password-field {
          position: relative;
          img {
            position: absolute;
            top: 50%;
            right: 20px;
            cursor: pointer;
            transform: translateY(-50%);
          }
        }
        input {
          width: 480px;
          height: 48px;
          background: #f5f5f5;
          border-radius: 8px;
          border: none;
          padding: 14px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          /* Heading */

          color: #3b3b3b;
          @media only screen and (max-width: 968px) {
            width: 350px;
          }
        }
        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
        }
      }
      .forgot-section {
        display: flex;
        flex-direction: row;

        align-items: center;

        width: 480px;
        justify-content: space-between;

        @media only screen and (max-width: 968px) {
          width: 350px;
        }

        .remember-me {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          /* Body Dark */

          color: #636363;
          display: flex;
          align-items: center;
          gap: 11px;
        }
        .forgot-pwd {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height */

          text-align: right;

          color: #979797;
        }
      }
      button {
        margin-top: 48px;
        border: none;
        width: 416px;
        height: 52px;
        &:disabled {
          background: $color_school_btn2;
        }
        background: $color_school_btn;
        border-radius: 5px;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;

        text-align: center;
        text-transform: uppercase;

        color: #ffffff;
        margin-bottom: 20px;
        @media only screen and (max-width: 1200px) {
          width: 300px;
        }
      }
    }
  }
}

// forgot password page

.login-right-recover {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: 100px;

    @media only screen and (min-width: 1368px) {
      margin-top: 261px;
    }
  }
  .heading-forgot {
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    margin-top: 40px;
    /* identical to box height */

    text-align: center;

    /* Heading */

    color: #3b3b3b;
    margin-bottom: 41px;
  }
  button {
    margin-top: 37px;
    border: none;
    width: 416px;
    height: 52px;

    // background: #979797;
    background: $color_school_btn;

    border-radius: 5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
    margin-bottom: 20px;
  }
  .form_control {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .password-field {
      position: relative;
      img {
        position: absolute;
        top: 50%;
        right: 20px;
        cursor: pointer;
        transform: translateY(-50%);
      }
    }
    input {
      width: 480px;
      height: 48px;
      background: #f5f5f5;
      border-radius: 8px;
      border: none;
      padding: 14px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      /* Heading */

      color: #3b3b3b;
    }
    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
