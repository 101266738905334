.lessondiv {
  display: flex;
  flex-wrap: wrap;
  .lessionContentAddCardMain {
    max-width: 23%;
    min-width: 23%;
    min-height: 160px;
    max-height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    margin-top: 50px;
    margin-bottom: 30px;
    cursor: pointer;

    .weekTitle {
      font-size: 22px;
      font-weight: 600;
      color: #9a9a9a;
    }
    .innerDivWithFooter {
      min-width: 100%;
      min-height: 100%;
      border: 1.26899px solid rgba(0, 0, 0, 0.2);
      border-radius: 8.88294px;
      overflow: hidden;
      margin: 10px;
      position: relative;
.removeMedia{
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background-color: $primaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
      .topDiv {
        min-height: 70%;
        background-color: #dadada;
      }

      .footerDiv {
        min-height: 30%;
        padding: 10px;
        display: flex;
        justify-content: space-between;
      }
    }
    .innerDiv {
      min-width: 100%;
      min-height: 100%;
      border: 1.26899px solid rgba(0, 0, 0, 0.2);
      border-radius: 8.88294px;
      overflow: hidden;
      margin: 10px;
      .topDiv {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .addContentText {
          width: 35%;
          color: #bfbfbf;
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }
}

// DATABASE TAB
.btnDiv {
  display: flex;
  justify-content: end;
  align-items: center;
  button {
    color: white;
    width: 128px;
    height: 48px;
    // background-color: #979797;
    background-color: $primaryColor;
    border-radius: 4.87385px;
    outline: none;
    border: 0ch;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
    }
  }
}
.mediaContainerDiv {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  .cardDiv {
    min-height: 170px;
    max-height: 170px;
    min-width: 210px;
    max-width: 210px;
    background-color: #c4c4c4;
    border-radius: 8px;
    margin-top: 40px;
    margin-right: 20px;
    position: relative;
    &:hover {
      .removeMedia {
        opacity: 1;
      }
    }
    .removeMedia {
      opacity: 0;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      background-color: $primaryColor;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      margin-top: -10px;
      margin-right: -10px;
      color: white;
    }
    .mediaIcon {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: 20px;
      margin-bottom: 15px;
    }
    .imageDiv {
      width: 50%;
      overflow: hidden;
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
    .descDiv {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: flex-start;
      padding-left: 5px;
      padding-bottom: 10px;
      .title {
        font-size: 16px;
        font-weight: 700;
        font-style: italic;
        color: #ffffff;
      }
      .subTitle {
        font-size: 8px;
        font-weight: 500;
        font-style: italic;
        color: #ffffff;
      }
    }
  }
}

// MEDIA EDIT MODEL CSS
.editMediaAddMediaMainDiv {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  .modeltitle {
    font-size: 29px;
    font-weight: 400;
  }
  .editMediaAddMediaMainDivInnerDiv {
    margin-top: 20px;
    min-width: 335px;
    max-width: 335px;
    min-height: 186px;
    max-height: 186px;
    border: 1.21714px dashed #bcbaba;
    background: #f9f9f9;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .subTitle {
      font-size: 22px;
      font-weight: 500;
      color: #bfbfbf;
    }
  }
}
.btn-groups {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 30px;
  .export {
    background-color: #625f5f !important;
  }
}
// MEDIA EDIT MODEL CSS ENDS

// CHARACTER MEDIA EDIT MODEL CSS
.editCharacterMediaAddMediaMainDiv {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 20%);
  .modeltitle {
    font-size: 25px;
    font-weight: 400;
  }
  input {
    width: 335px;
    background: #f9f9f9;
    border: 1.21714px solid #bcbaba;
    border-radius: 8.52px;
    padding: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .editMediaAddMediaMainDivInnerDiv {
    margin-top: 20px;
    min-width: 335px;
    max-width: 335px;
    min-height: 186px;
    max-height: 186px;
    border: 1.21714px dashed #bcbaba;
    background: #f9f9f9;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .subTitle {
      font-size: 22px;
      font-weight: 500;
      color: #bfbfbf;
    }
  }
}
.btn-groups {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 30px;
  .export {
    background-color: $primaryColor !important;
  }
}
// CHARACTER MEDIA EDIT MODEL CSS ENDS

.editContentMainDiv{
  .topBar .acionBtn {
    font-weight: 500;
    font-size: 16px;
    text-decoration-line: underline;
    color: #EF5B2C;
    &:hover{
      cursor: pointer;
    }
}
}

// END

.languageCardMain{
  display: flex;
  flex-wrap: wrap;
  .languageCard{
    width: 25%;
    // min-height: 350px;
    padding: 10px;
    .innerDiv{
      border: 1px $color_school_btn solid;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }
}
