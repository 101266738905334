@import "../variables";

.newSchoolEntry {
  padding: 10px 81px 10px 37px;
  .progressBar{
    // background-color: red;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .circleDiv{
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px #C4C4C4 solid;
      color: #C4C4C4;
      img{
        display: none !important;
      }
    }
    .lineDiv{
      width: 30%;
      height: 5px;
      background-color: #C4C4C4;
    }
    .inProgress{
      border: 2px $primaryColor solid;
      color: $primaryColor;
      img{
        display: none;
      }
    }
    .completed{
      background-color: $primaryColor;
      border: 2px $primaryColor solid;

      img{
        display: block !important;
        color: white;
      }
      span{
        display: none;
      }
    }
    .completedLineDiv{
      background-color: $primaryColor;
    }
  }
  .entrySection {
    // padding: 20px 10px;
    padding-top: 54px;
    display: flex;
    flex-direction: row;
    .form-group-inner {
      margin-bottom: 25px;
      .schoolAdmin {
        display: flex;
        gap: 10px 0;
        flex-direction: column;
        .contact {
          display: flex;
          flex-direction: row;
          gap: 5px;
          width: 350px;
          select {
            width: 20%;
          }
          input {
            width: 80%;
            border: 1px solid rgba(0, 0, 0, 0.2);
            box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
border-radius: 53px;
padding: 10px;
          }
          .PhoneInput {
            display: flex;
            width: 100%;
            align-items: center;
        }
        }
      }
      .package-type-group {
        display: flex;
        gap: 5px;
        width: 350px;
        button {
          background: $primaryColor;
          color: white;
          border-radius: 5px;
          width: 50%;
          font-weight: normal;
          font-size: 12px;
          border: none;
        }
      }
    }
    label {
      //
      display: block;
      font-weight: 600;
      font-size: 20px;
      line-height: 29px;
      margin-bottom: 10px;
      font-weight: 600;
    }
    input,
    select {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      width: 350px;
      // height: 35px;
      font-weight: 300;
      font-size: 12px;
      padding: 10px;

      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
border-radius: 53px;
    }

    select {
      // width: 50%;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url('data:image/svg+xml;utf8,<svg width="13" height="8" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.99992 7.58586L16.2928 0.292969L17.707 1.70718L8.99992 10.4143L0.292818 1.70718L1.70703 0.292969L8.99992 7.58586Z" fill="black"/></svg>');
      background-repeat: no-repeat;
      background-position-y: 50%;
      background-position-x: 96%;
      padding: 10px;
    }
    label {
      span {
        font-weight: bold;

        margin-left: -3px;
      }
    }

    .entrySection-left {
      width: 50%;
      .form-group-inner {
        .subscriptionPeriod {
          > div {
            // border:1px solid red;
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
            span {
              width: 50px;
              font-size: 15px;
            }

            input {
              width: 30%;
              // border-bottom: 1px solid green;
            }
          }
        }
      }
    }
    .entrySection-right {
      width: 50%;

      .button-entry-school {
        display: flex;
        padding-right: 20px;
        gap: 20px;
        align-items: flex-end;
        justify-content: flex-end;
        button:nth-child(1) {
          background: $primaryColor;
        }
        button {
          width: 150px;
          height: 35px;
          left: 1220px;
          top: 938px;

          background: #cdcdcd;
          border-radius: 6px;
          color: white;
          border: none;
          font-weight: 400;
          font-size: 15px;
        }
      }
    }
  }
  .manageTextContentMainDiv{
    .title{
      font-family: 'WorkSans';
font-style: normal;
font-weight: 600;
font-size: 38px;
line-height: 95%;
color: #000000;
    }
    .tabs-section{
       button[aria-selected="true"]{
           color: $primaryColor;
           border-bottom: 4px $primaryColor solid;
       }
       .MuiTabs-indicator{
           height: 0px !important;
           border-bottom: 4px $primaryColor solid !important;

       }
      }
  }
}
.newEntryBottom-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  bottom: 53px;

  justify-content: center;
  .btn-next {
    width: 180px;
    height: 46px;
    left: 1220px;
    top: 938px;

    background: #cdcdcd;
    border-radius: 6px;
    color: white;
    border: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
  .btn-done {
    background-color: $dark-btn-color;
    cursor: pointer;
  }
}

.image-upload {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .image-upload-inner {
    margin: 40px auto;

    // width: 390px;
    width: 60%;
    height: 222.48px;
    padding: 29px 0;

    border: 0.886364px solid #c2c2c2;
    border-radius: 8.86364px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    label {
      img {
        cursor: pointer;
      }
    }
    .input-file {
      display: none;
      // dis
    }
    span {
      font-weight: 600;
      font-size: 15.9545px;
      line-height: 95%;
      /* or 15px */

      text-transform: uppercase;

      color: #979797;
    }
    .change-img {
      width: 127.64px;
      height: 30.14px;
      line-height: 30.14px;
      text-align: center;
      font-weight: 400;
      font-size: 14.1818px;
      cursor: pointer;
      /* or 13px */

      color: #979797;

      border: 0.886364px solid #c2c2c2;
      border-radius: 7.09091px;
    }
  }
  .error-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 30px;
    gap: 16.25px;
    span {
      text-align: left;
      font-weight: 400;
      font-size: 16px;
      line-height: 95%;

      color: #979797;
    }
  }

}
.manageTextContentMainDiv{
  .button-entry-school {
    display: flex;
    padding-right: 20px;
    gap: 20px;
    align-items: flex-end;
    justify-content: flex-end;
    button:nth-child(1) {
      background: $primaryColor;
    }
    button {
      width: 150px;
      height: 35px;
      left: 1220px;
      top: 938px;

      background: #cdcdcd;
      border-radius: 6px;
      color: white;
      border: none;
      font-weight: 400;
      font-size: 15px;
    }
  }
}

.image-upload-video {
  display: flex;
  // align-items: center;
  justify-content: center;
  gap: 10px;
  overflow-x: hidden;

  flex-direction: row;
  .image-upload-inner {
    width: 206px;
    height: 223px;
    // padding: 0;
  }
  .error-upload {
    .subtitle {
      input {
        display: none;
      }
      label {
        width: 180px;
        cursor: pointer;
        height: 46px;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        border: none;
        background: $primaryColor;
        border-radius: 6px;
        display: flex;
        align-items: center;
        gap: 7px;
        letter-spacing: -1px;

        color: #ffffff;
        justify-content: center;
        span {
          text-transform: capitalize;
          font-weight: 400;
          font-size: 18px;
          letter-spacing: -1px;

          color: #ffffff;
        }
      }
    }
  }
}

.questions-upload {
  display: flex;
  align-items: center;
  margin-top: 37px;
  gap: 35px;
  flex-direction: column;
  .form-group {
    padding: 0 20px;
    display: flex;
    gap: 13px;
    flex-direction: column;
    .qn-header {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: black;
    }
    .qn-content {
      border: 1px solid #c2c2c2;
      border-radius: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 95%;
      color: black;
      padding: 18px;
    }
  }
  .uploadMore {
    width: 380px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #dadada;
    border-radius: 8px;
    img {
      cursor: pointer;
    }
  }
}
// question list
.questionList{
  .question{
    margin-bottom: 37px;
    .heading{
      font-weight: 400;
font-size: 19.2302px;
line-height: 23px;

// text-align: center;
letter-spacing: -1.06835px;

color: #000000;
margin-bottom: 14px;
    }
    .question-content{
      width: 100%;


border: 1.06835px solid #C2C2C2;
border-radius: 8.54676px;
padding: 20px;
font-style: normal;
font-weight: 400;
font-size: 17.0935px;
line-height: 95%;
/* or 16px */



color: #979797;
    }
  }
}
.uploadMore_here{
  width: 470.07px;
height: 33.12px;


background: #DADADA;
border-radius: 8.54px;
margin:auto;
display: flex;
align-items: center;
justify-content: center;



}
.week_select{
  position:absolute;
  right:10px;
  top: 10px;
  span{
    font-weight: 600;
font-size: 14px;
line-height: 17px;
/* identical to box height */

letter-spacing: 0.0327273px;

color: #000000;
margin-bottom: 7px;
display: block;
  }
  .newSelect{
    // background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      width: 350px;
      // height: 35px;
      font-weight: 300;
      font-size: 12px;
      padding: 10px;

      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.05);
border-radius: 53px;

padding: 10px;
width: 209px;
// height: 26px;

color: black;
background: #FFFFFF;
border: 0.5px solid rgba(0, 0, 0, 0.2);
border-radius: 3px;
  }
}

// date picker navigation icons
.subscriptionPeriod 
{
  .react-datepicker__navigation-icon--previous{
  right:20px !important;
}
}
.subscriptionPeriod 
{
.react-datepicker__navigation-icon--next{
  left:20px !important;}
}