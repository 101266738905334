// $color_school_btn:#727272;
// $color_school_btn2:#B5B5B5;
// $primaryColor:#EF5B2C;
// $activePageColor:#FFCA2A;
// $container_background:#F5F5F5;
// $open-btn-color:#737373;
// $close-btn-color: #C4C4C4;
// $light-btn-color:#797878;
// $dark-btn-color:#9E9E9E;

// primary orange
// text black
// 

$color_school_btn:#727272;
$color_school_btn2:#B5B5B5;
$primaryColor:#0062D5;
$activePageColor:#FFCA2A;
$container_background:#F5F5F5;
$open-btn-color:#737373;
$close-btn-color: #C4C4C4;
$light-btn-color:#797878;
$dark-btn-color:#9E9E9E;
