.dashboard{
    max-width: calc(100vw - 120px);
    overflow-x: hidden;
    
    // padding:57px 40px 0 35px ;
    display: flex;
    .dashboard-left{
        // flex: 70%;
    padding:31px 38px 0 35px ;

        width:70%;
    
        
 
        .tabs-section{
           margin-bottom: 33px;
            display: flex;
            justify-content: space-between;
            button[aria-selected="true"]{
                color: $primaryColor;
                border-bottom: 4px $primaryColor solid;
            }
            .MuiTabs-indicator{
                height: 0px !important;
                border-bottom: 4px $primaryColor solid;

            }
            .input-box-outer{
                position: relative;
                width: 189px;
                    height: 34px;
                .input-box{
                    width: 189px;
                    height: 34px;
                    background: #FFFFFF;
                    border: 1px solid rgba(0, 0, 0, 0.2);
    
                    border-radius: 2px;
                   
                    padding: 9px;

                    &::placeholder{
                        font-family: WorkSans;
                        font-style: italic;
                         font-weight: 300;
                      font-size: 14px;
                     
                    }
                }
               
                img{
                    position: absolute;
                    right: 9.72px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
           
        }
    }
    .dashboard-right{
        // border: 1px solid green;
        // flex: 30%;
        width: 30%;
        // max-width:calc(30vw - 120px  );
        

        // display: flex;
      
       flex-direction: column;
       padding-right: 40px;
       .btns{
           display: flex;
           flex-direction: column;
           gap: 20px;
           justify-content: center;
           align-items:flex-end;
          

        .dashboard-btn{
            
            background: $primaryColor;
            border-radius: 7px;
            border: none;
            color: #FFFFFF;
            font-size: 10px;
            width: 210px;
           height: 34px;
        }
       }
       .license{

      background: #F5F5F5;
      margin-top: 25px;
      padding:21px 42px 0 21px;
      
      border-radius: 15px;
      h3{
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 15px;
      }
      div{
         span{
             &:nth-child(1){
                font-weight: 600;
                font-size: 36px;
             }
             &:nth-child(2){
                font-weight: 600;
                font-size: 14px;
              
              
                text-decoration-line: underline;
                
             }
         }
      }
       }
       .license-list{
        background: #F5F5F5;
        border-radius: 15px;
        margin-top: 18px;
        padding:21px 42px 0 21px;


      .license-1{
          .license-1_inner{
              display: flex;
              align-items: center;
              gap: 11px;
          }
          span{
            font-weight: normal;
            font-size: 14px;
          }
      }
      h4{
        font-weight: 600;
        font-size: 18px;
      }
      .school{
          margin-top: 23px;
          div{
              display: flex;
            //   gap: 10px;
            
              justify-content: space-between;
              flex-flow: row wrap;
              ul{
                  margin: 0;
              width: 120px;

                  li{
                      display: flex;
                      align-items: center;
                      gap: 11px;

                      span {
                        font-weight: normal;
                        font-size: 14px;
                      }
                  }
              }
          }
      }
       }
        
    }
}

.content-inner{
    display: flex;
    flex-direction: column;
    gap: 12px;
  
    &.content-inner-license{
height: 350px;
overflow-y: auto;
    }
    .content-inner-details{

background: #F5F5F5;
border-radius: 7px;
padding: 9px 20px 9px 20px;
display: flex;
justify-content: space-between;

flex-direction: row;
align-items: center;
gap: 20px;
.upper{
    .school{
        font-weight: 500;
font-size: 18px;
line-height: 22px;
letter-spacing: 0.0327273px;
    }
    .last-renew, .expiry-date{
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.0327273px;
        
    }
    .expiry-date{
        span{
color: rgb(179,179,179);
        }

    }
}
    }
    .contact{
        font-weight: 400;
font-size: 14px;
line-height: 17px;
text-decoration-line: underline;
text-underline-offset: 2px;

letter-spacing: 0.0327273px;

    }
}
.btn-groups{
    display: flex;
    align-items: center;
    gap: 13px;
    margin-top: 50px;
    justify-content: center;
    button{

        width: 180px;
height: 46px;
border: none;
// line-height: 46px;

border-radius: 6px;
color: white;
text-align: center;
font-weight: 400;
font-size: 18px;
line-height: 22px;

letter-spacing: -1px;
    }
    .export {
        background: #AFAFAF;
    }
    .cancel{
background: #CDCDCD;


    }
}

.addPackageBtn{
    background-color: $primaryColor;
    color: #FFFFFF;
    border-radius: 5px;
    outline: none;
    border: 0ch;
    padding: 3px;
    margin-left: 10px;
}