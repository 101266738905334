.analyticsMain {
  .tabs-section {
    button[aria-selected="true"] {
      color: $primaryColor;
      border-bottom: 4px $primaryColor solid;
    }
    .MuiTabs-indicator {
      height: 0px !important;
      border-bottom: 4px $primaryColor solid;
    }
  }
}

.tabpanel-content {
  margin-top: 30px;
  .header {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 25px;
  }
  .top-section {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .top-section-left {
      width: 30%;
      // border: 1px solid red;

      display: flex;
      flex-direction: column;
      gap: 21px;
      // flex-flow: row wrap;
      .top-section-left-top {
        width: 325px;
        height: 142px;
        padding: 17.9px 22px 25px 19px;

        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 7px;
        // background-color: red;

        .upper {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
        .lower {
          margin-top: 40px;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
        }

        .large-font {
          font-size: 36px;
        }
      }

      .top-section-game-left-top {
        width: 100%;
        height: 50%;
        padding: 17.9px 22px 25px 19px;
        cursor: pointer;

        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 7px;
        // background-color: red;

        .upper {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
        .lower {
          margin-top: 40px;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
        }

        .large-font {
          font-size: 36px;
          word-break: break-word;
        }
      }

      .top-section-left-bottom {
        width: 325px;

        height: 142px;

        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);

        box-sizing: border-box;
        border-radius: 7px;
        .upper {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
        .lower {
          margin-top: 40px;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
        }
        .large-font {
          font-size: 36px;
        }
      }

      .top-section-game-left-bottom {
        width: 100%;
        height: 50%;
        cursor: pointer;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);

        box-sizing: border-box;
        border-radius: 7px;
        .upper {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
        }
        .lower {
          margin-top: 40px;
          font-weight: 500;
          font-size: 24px;
          line-height: 29px;
        }
        .large-font {
          font-size: 36px;
        }
      }
    }
    .top-section-middle {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      // width: 440px;
      height: 305px;
      position: relative;
      border-radius: 7px;
      overflow: hidden;
      width: 35%;

      // display: flex;
      // flex-direction: column;

      .upper {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
      }
      .lower {
        // border: 1px solid red;
        margin-top: 40px;
        justify-content: space-between;
        //     font-weight: 500;
        // font-size: 24px;
        // line-height: 29px;
      }
    }
    .top-section-right {
      width: 35%;
      overflow: hidden;

      // width: 440px;
      height: 305px;

      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);

      position: relative;
      border-radius: 7px;
      .upper {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
      }
      .lower {
        // border: 1px solid red;
        margin-top: 40px;
        justify-content: space-between;
        //     font-weight: 500;
        // font-size: 24px;
        // line-height: 29px;
      }
    }
  }
}
.spacing {
  padding: 17.9px 22px 25px 19px;
}
.bottom-section {
  margin-top: 95px;
  margin-bottom: 13px;
  display: flex;
  flex-direction: row;
  gap: 225px;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    .performance {
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
      margin-right: 20px;
    }
  }
  .right {
    .right-top {
      display: flex;
      // width: 50%;
      gap: 30px;
      align-items: center;
      justify-content: space-between;

      .group {
        display: flex;
        align-items: center;
        gap: 27px;
        span {
          margin-top: 5px;
        }
      }
    }
    .right-bottom {
      display: flex;
      margin-top: 13px;
      align-items: flex-end;
      gap: 20px;

      input {
        width: 209px;
        padding: 8px;
        height: 26px;

        background: #ffffff;
        border: 0.5px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 3px;
      }
    }
  }
}

.btn-analytics {
  width: 97px;
  height: 32px;
  left: 536px;
  top: 741px;

  // background: #c4c4c4;
  background-color: $primaryColor;
  border-radius: 7px;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  border: none;
  color: white;
}
.btn-analytics-new{
  background-color: #DB4C86;

}
.btn-analytics-new1{
  background-color: #6A51BE;

}

.btn-cancel {
  width: 97px;
  height: 32px;
  left: 536px;
  top: 741px;

  // background: #c4c4c4;
  background-color: #c4c4c4;
  border-radius: 7px;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  border: none;
  color: white;
}

.most-bottom {
  display: flex;
  gap: 12px;
  // justify-content: space-between;

  max-width: 100%;
  flex-flow: wrap;

  .most-bottom-left {
    height: 314px;
    padding: 23px 22px 20px 28px;

    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 7px;
    position: relative;

    .header-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
      }
      .middle-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 23px;
      }
    }
    .middle-section {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      justify-content: space-between;

      .left {
        .info {
          display: flex;
          align-items: center;
          gap: 9px;
        }
      }
      .right {
        // margin-left: 50px;
        overflow: hidden;

        .heading {
          font-weight: 300;
          font-size: 12px;
          line-height: 15px;
          margin-top: 25px;

          margin-bottom: 10px;
        }
      }
    }
  }
}

.back-btn-1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

// analytics system css
.tabpanel-content {
  .header-system {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
}
.analyticsSystem-outer {
  display: flex;
  // align-items: center;
  gap: 20px;
  flex-direction: row;
  .header {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.0327273px;
  }
  .group {
    display: flex;
    gap: 11px;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    color: black;

    letter-spacing: 0.0327273px;
    label {
      background: transparent !important;
    }
  }
  .header-sm {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: black;

    letter-spacing: 0.0327273px;
    margin: 23px 0 10px 0;
  }
  .selectGroup {
    width: 100%;
    height: 26px;
    display: flex;
    flex-direction: row;
    gap: 13px;

    .select-1 {
      background: #ffffff;
      border: 0.5px solid rgba(0, 0, 0, 0.2);

      border-radius: 3px;
      // width: 89px;
      width: 20%;
    }
    input {
      width: 80%;
      // width: 185px;
      padding: 3px;

      background: #ffffff;
      border: 0.5px solid rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      border-radius: 3px;
    }
  }
  .checkbox-containers {
    display: flex;
    margin-bottom: 34px;
    flex-direction: row;
    flex-flow: row wrap;
    gap: 12px;
    .group {
      width: 140px;
      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;

        background: #ffffff;
        // border: 0.5px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 3px;
      }
    }
    .checkbox-containers {
      display: flex;
      margin-bottom: 34px;
      flex-direction: row;
      flex-flow: row wrap;
      gap: 12px;
      .group {
        width: 140px;

        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;

          letter-spacing: 0.0327273px;
        }
      }
      letter-spacing: 0.0327273px;
    }
  }
}

// analytics media

.analyticsMediaContainer {
  padding: 20px;
  margin-top: 27px;
  // padding-right: 20px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);

  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  .heading-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tabs-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button[aria-selected="true"] {
      color: $primaryColor;
      border-bottom: 4px $primaryColor solid;
    }
    .MuiTabs-indicator {
      height: 0px !important;
      border-bottom: 4px $primaryColor solid;
    }
  }

  .video-content {
    display: flex;
    align-items: center;
    gap: 18px;
    .video-content-inner {
      position: relative;
      video {
        width: 117px;
        height: 72px;
      }
      img {
        position: absolute;
        cursor: pointer;

        left: 50%;
        top: 50%;
        object-fit: cover;
        width: 20px;

        height: 20px;

        transform: translate(-50%, -50%);
      }
    }
  }
}

.middle-content-export {
  margin-top: 26px;
  .group {
    display: flex;
    align-items: center;
    gap: 11px;
    // justify-content: center;
    margin-bottom: 27px;
    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;

      letter-spacing: 0.0327273px;
    }
  }
}
.video-content-inner-modal {
  position: relative;
  // overflow: hidden;
  background: transparent;
  video {
    width: 500px;
    height: 100%;
  }
  img {
    position: absolute;
    z-index: 1000;
    left: 50%;
    top: 50%;
    width: 15px;

    height: 15px;

    transform: translate(-50%, -50%);
  }
}

.input-box-outer {
  position: relative;
  width: 189px;
  height: 34px;
  .input-box {
    width: 189px;
    height: 34px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);

    border-radius: 2px;

    padding: 9px;

    &::placeholder {
      font-family: WorkSans;
      font-style: italic;
      font-weight: 300;
      font-size: 14px;
    }
  }
}
.input-box-outermost{
  display: flex;
  align-items:center;
  justify-content: flex-end;
  gap: 10px;
}
.newSelect{
  width: 189px;
  margin-bottom: 20px;
    height: 34px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    // margin-left:auto;
}