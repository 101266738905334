.weeklyquiz-container{
    .header{
        font-weight: 700;
font-size: 24px;
line-height: 29px;
margin-top: 30px;
    }
    .content{
        .accordion-content{
            width: 75%;
            .accordion-heading-1{
                font-weight: 500;
font-size: 14px;
line-height: 17px;
            }
        }
    }
   
}

.accordion-inner-content{
    padding: 31px 36px 58px 47px;
    display: flex;
    // align-items: center;
    gap: 59px;
    .leftContent{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 25%;
        .time-content{
            display: flex;
            gap: 20px;
            align-items: center;
            justify-content: center;
        }
    }
    .right-content{
        width:75% ;
        font-weight: 400;
font-size: 12px;
line-height: 15px;
    }
}

.option-here{
    margin-top: 20px;
    .progress{
       width : 100%;
        height: 15.67px;
// height: 379.52px;


background: #EF5B2C;
border-radius: 245px;
margin-top: 7px;
text-align: right;
color: white;
// padding-right: 3px;
display: flex;
align-items: center;
justify-content: right;
    }
}
.msg-week-error{
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    margin-top: 30px;
}