.manage-accounts{
    .tabs-section{
        button[aria-selected="true"]{
            color: $primaryColor;
            border-bottom: 4px $primaryColor solid;
        }
        .MuiTabs-indicator{
            height: 0px !important;
            border-bottom: 4px $primaryColor solid;

        }
    }
    .manage-accounts-outer{
        border: 1px solid red;
    }
    
    .manage-accounts-inner{
    display: flex;
    gap: 20px;
    


    .manage-account-left{
        width: 70%;
    }
    .manage-account-right{
        width: 30%;
        margin-top: 25px;
        background-color: $container_background;
        padding: 21px 42px 30px 21px;
        border-radius: 15px;

        .data{
            font-weight: 400;
font-size: 14px;
line-height: 17px;
margin-top: 9px;
display: flex;
gap: 11px;
align-items: center;
        }
        .header{
            font-weight: 600;
font-size: 18px;
line-height: 22px;
        }
        .sorting{

        }
        .access-levels{
            margin-top: 31px;
            .access-levels-inner{
                display: flex;
                gap:0 120px;
                
                align-items: left;
                justify-content: flex-start;
                flex-wrap: wrap;
            }
            

            


        }

    }
}
}
.manage-account-btn{
    position: absolute;
    top:110px;
    right: 40px;
    display: flex;
    justify-content: flex-end;
    .school-btn{
        background-color: $primaryColor;
    }
}
.form-manage_accounts{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
//   margin-bottom: 40px;

    .form-group{
        margin-bottom: 16px;
        label{
            width: 95px;
            height: 27px;
            line-height: 15px;
            font-weight: 400;
            margin-right: 20px;
font-size: 12px;
        }
        input,select{
            width: 210px;
height: 27px;


background: #FFFFFF;
border: 1px solid rgba(0, 0, 0, 0.2);

border-radius: 3px;
        }
    }
}