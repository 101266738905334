.schoolHomeMain{
    .tabs-section{
        margin-bottom: 33px;
         button[aria-selected="true"]{
             color: $primaryColor;
             border-bottom: 4px $primaryColor solid;
         }
         .MuiTabs-indicator{
             height: 0px !important;
             border-bottom: 4px $primaryColor solid;

         }
        }
}


// delete student modal
.studentwarn{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    transform: translate(-50%,-50%);
    height: 400px;
    background-color: white;
    z-index: 1000;
}