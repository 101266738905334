.gameBackgroundUploadMain{
    padding: 20px;
    .displayImage{
        width: 200px;
        height: 200px;
        border-radius: 10px;
        margin-right: 10px;
        overflow: hidden;
        margin-top: 20px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .removeMedia{
            display: none;
            
        }
        &:hover{
            position: relative;
            .removeMedia{
                display: block;
                position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            background-color: $primaryColor;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img{
                width: 90%;
                height: 90%;
            }
            }
        }
    }
    .uploadScreenMain{
        display: flex;
        .leftDiv{
            width: 40%;
        }
        .right{
            width: 60%;
        }
        .leftDiv{
            .inputField{
                display: flex;
                flex-direction: column;
                label{
                    margin-bottom: 20px;
                }
                input{
                    width: 90%;
                    padding: 10px;
                    border: 1px grey solid;
                    outline: none;
                    border-radius: 10px;
                }
            }
           
        }
        .right{
            .footerBar{
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                width: 90%;
                button{
                    width: 200px;
                    height: 50px;
                    border-radius: 10px;
                    outline: none;
                    border: 0ch;
                    color: white;
                }
                .create{
                    background-color: $primaryColor;
                }
                .cancel{
                    background-color: $color_school_btn2;
                }
            }
        }
    }
}