.container-page {
  .input-box-outer {
    position: relative;
    width: 210px;
    height: 34px;
    .input-box {
      width: 210px;
      height: 34px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.2);

      border-radius: 2px;

      padding: 9px;

      &::placeholder {
        font-family: WorkSans;
        font-style: italic;
        font-weight: 300;
        font-size: 14px;
      }
    }

    img {
      position: absolute;
      right: 9.72px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
