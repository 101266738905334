.sidebar{
    position: fixed;
    width: 110px;
    top: 0;
    left: 0;
    margin-left: 5px;
    overflow: hidden;
    
    height: 98vh;  
    margin-top: 5px;
    background-color: $primaryColor;
    box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.25);
    // background-image: url("../../../public/icons/sidebarRectangle.svg");
    .sidebar-wrapper{
        position: relative !important;
       
height: 100%;
.icon{
    height: 30px;
}
.sidebar-item{
    left: 50%;
    transform: translate(-50%);
    a{
        position: absolute;
      
       
        left: 50%;
        transform: translate(-50%);
    display: flex;
    align-items: center;
    
    width: 120px;
    flex-direction: column;
    
    justify-content: center;
    
    
  
    span{
        margin-top: 5px;
       
        width: 100px;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
                    
                    padding: 3px;
                    color: white;
        text-align: center;
    };
    }
    
}

.active{
    a{
        // img{
        //     filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
        // }
        span{
            color: $activePageColor !important;
        }
    }
    // border: 2px $activePageColor solid;
}

      
        
        
   
  
        .sidebar-top{
            position: absolute;
            background-color: $primaryColor;
            // background-image: url("../../../public/icons/sidebarTop.svg");
            background-repeat: no-repeat;
            top: -130px;
            left: 0;
            height: 377px;
            width: 100%;
          
            z-index: 1000;
           
           
            .sidebar-item-1{
                position: absolute;
                top: 125px;
               
               
                .icon-1{
                    height: 100%;
                }
            }
            .sidebar-item-2{
                z-index: 10000;
                position: absolute;
                top: 285px;
                border:1px solid grey;
                a{
                    icon{
                        height: 200px !important;
                    }
                }
                

            }
           
            
       
        }
        .sidebar-middle{
            position: absolute;
            background-color: $primaryColor;
            // background-image: url("../../../public/icons/sidebarMiddle.svg");
            background-repeat: no-repeat;
            top: -140px;
            width: 100%;
            left: 0;
            height: 799.85px;
            .sidebar-item-3{
                position: absolute;
                top: 385px;
            }
            .sidebar-item-4{
                position: absolute;
                top: 480px;
            }
        .sidebar-item-5{
            position: absolute;
                top: 560px;
                a{
                    .icon{
                        height: 20.42px;
                    }
                }
               
              
        }
      
        .sidebar-item-6{
            position: absolute;
                top: 640px;
                
        }
       
        .sidebar-item-7{
            position: absolute;
                
                bottom: 35px;
                
               
        }

        }
        .sidebar-bottom{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin-bottom: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .logoutDiv{
                background-image: url("../../assets/Union.svg");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                width: 100%;
                height: 40px;
                margin-bottom: 10px;
                display: none;
                cursor: pointer;
                span{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 9px;
                    line-height: 11px;
                    color: #FFFFFF;
                    display: inline-block;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                }
            }
            &:hover{
                .logoutDiv{
                    display: block;
                }
            }
        }
    }

   
}