.globalGameManagementMain{
    display: flex;
    .cardMain{
        max-width: 23%;
        min-width: 23%;
        min-height: 160px;
        max-height: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;
        margin-top: 50px;
        margin-bottom: 30px;
        cursor: pointer;
        .weekTitle {
            font-size: 16px;
            font-weight: 600;
            color: #9a9a9a;
        }
        .innerDiv{
            min-width: 100%;
            min-height: 100%;
            border: 1.26899px solid rgba(0, 0, 0, 0.2);
            border-radius: 8.88294px;
            overflow: hidden;
            margin: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center; 
        }
    }
}